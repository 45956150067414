<script lang="ts">
	import { onMount } from 'svelte'
	import { fade } from 'svelte/transition'
	import { CldImage } from 'svelte-cloudinary'

	import { badgeVariants } from '$lib/components/ui/badge'
	import { Button } from '$lib/components/ui/button'
	import * as Card from '$lib/components/ui/card'
	import { Separator } from '$lib/components/ui/separator'
	import * as m from '$lib/paraglide/messages'
	import { phg } from '$lib/utils/posthog'

	import type { PageData } from './$types'

	export let data: PageData

	let idx = 0
	let strings = [
		m.home_hero_tagline_word1(),
		m.home_hero_tagline_word2(),
		m.home_hero_tagline_word3(),
	]

	onMount(() => {
		const interval = setInterval(() => {
			if (idx < strings.length - 1) {
				idx++
			} else {
				idx = 0
			}
		}, 3000)

		return () => {
			clearInterval(interval)
		}
	})
</script>

<main class="wrapper pb-24 pl-4 pr-4 pt-4 sm:pb-28 sm:pl-6 sm:pr-6 sm:pt-6">
	<section class="flex flex-col items-center gap-6 text-center sm:pt-28">
		<div
			class="flex min-h-[calc(100svh-6rem)] flex-col items-start justify-center gap-8 sm:min-h-[unset] sm:items-center sm:gap-6"
		>
			<a class={badgeVariants({ variant: 'default' })} href="/compare">{m.home_hero_badge()}</a>

			<h1
				class="flex flex-col items-start text-4xl font-bold leading-[1.05] 2xs:text-5xl sm:items-center sm:text-6xl"
			>
				<p class="text-start sm:text-center">{m.home_hero_tagline_start()}</p>

				<p>
					<span>{m.home_hero_tagline_middle()}</span>

					{#key idx}
						<span class="text-branding-gradient" in:fade={{ duration: 500 }}>
							{strings[idx]}
						</span>
					{/key}
				</p>
			</h1>

			<h2
				class="links-inside text-left text-lg font-semibold text-muted-foreground xs:max-w-[600px] sm:text-center sm:text-base sm:font-medium"
			>
				<!-- eslint-disable-next-line svelte/no-at-html-tags -->
				{@html m.home_hero_description({
					ethereum: '/chain/ethereum',
					bitcoin: '/chain/bitcoin',
					solana: '/chain/solana',
				})}
			</h2>

			<Button
				href="/dashboard"
				on:click={() => {
					phg?.capture('open_dashboard', {
						place: 'main_banner',
					})
				}}
			>
				{m.dashboard_cta()}
			</Button>
		</div>

		<div class="top-gradient absolute right-0 top-0 -z-1 h-[160vh] w-full" />

		<div style="width: 780px; max-width: 100%;" class="relative sm:mt-20">
			<CldImage
				alt={m.home_hero_macbook_screenshot_alt()}
				decoding="async"
				fetchpriority="high"
				height="823"
				loading="lazy"
				priority
				src="landing/macbook_redesign_vb2peq"
				width="1353"
			/>

			<div style="max-width: 24%;" class="absolute bottom-0 right-1 sm:right-4">
				<CldImage
					alt={m.home_hero_iphone_screenshot_alt()}
					decoding="async"
					fetchpriority="high"
					height="708"
					loading="lazy"
					priority
					src="landing/iphone_redesign_xuexgz"
					width="352"
				/>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-32 sm:pt-40">
		<div class="flex flex-col items-center">
			<h3 class="text-center text-xl font-semibold sm:text-2xl">
				{m.home_partners_title()}
			</h3>

			<div
				class="mt-8 grid grid-cols-2 grid-rows-4 items-center justify-center gap-10 sm:grid-cols-8 sm:grid-rows-2 sm:gap-8"
			>
				<div class="flex items-center justify-center">
					<CldImage
						alt="Web3 Foundation"
						decoding="async"
						format="svg"
						height="48"
						loading="lazy"
						src="partners/web3-foundation_ribdtd"
						width="112"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="BNB Chain"
						decoding="async"
						format="svg"
						height="24"
						loading="lazy"
						src="partners/bnb-chain_vxgxul"
						width="137"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Hedera"
						decoding="async"
						format="svg"
						height="38"
						loading="lazy"
						src="partners/hedera_jfdier"
						width="126"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Polkadot"
						decoding="async"
						format="svg"
						height="30"
						loading="lazy"
						src="partners/polkadot_avbc50"
						width="140"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Stellar"
						decoding="async"
						format="svg"
						height="32"
						loading="lazy"
						src="partners/stellar_xpmipo"
						width="130"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Aptos"
						decoding="async"
						format="svg"
						height="30"
						loading="lazy"
						src="partners/aptos_xowanj"
						width="122"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Fantom"
						decoding="async"
						format="svg"
						height="36"
						loading="lazy"
						src="partners/fantom_ygrkwi"
						width="140"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="NEAR"
						decoding="async"
						format="svg"
						height="29"
						loading="lazy"
						src="partners/near_do7e39"
						width="124"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Telos"
						decoding="async"
						format="svg"
						height="38"
						loading="lazy"
						src="partners/telos_pqaltr"
						width="100"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Arthera"
						decoding="async"
						format="svg"
						height="34"
						loading="lazy"
						src="partners/arthera_glxkja"
						width="126"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Sei"
						decoding="async"
						format="svg"
						height="30"
						loading="lazy"
						src="partners/sei_xxwsr5"
						width="80"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Starknet"
						decoding="async"
						format="svg"
						height="18"
						loading="lazy"
						src="partners/starknet_nrtv5e"
						width="124"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Moonbeam"
						decoding="async"
						format="svg"
						height="22"
						loading="lazy"
						src="partners/moonbeam_uf5dsy"
						width="151"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Concordium"
						decoding="async"
						format="svg"
						height="30"
						loading="lazy"
						src="partners/concordium_il9kqd"
						width="162"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Cardano"
						decoding="async"
						format="svg"
						height="27"
						loading="lazy"
						src="partners/cardano_yeltwh"
						width="138"
					/>
				</div>

				<div class="flex items-center justify-center">
					<CldImage
						alt="Bware Labs"
						decoding="async"
						format="svg"
						height="29"
						loading="lazy"
						src="partners/bware_labs_jtlfvi"
						width="128"
					/>
				</div>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-24 sm:pt-40">
		<h3 style="max-width: 700px;" class="text-center text-2xl font-bold sm:text-4xl">
			{m.home_numbers_title()}
		</h3>

		<p
			style="max-width: 700px;"
			class="mt-6 px-1 text-center text-base text-muted-foreground sm:px-0"
		>
			{m.home_numbers_description()}
		</p>

		<div
			class="mt-10 grid w-full grid-cols-1 grid-rows-[1fr_auto_1fr_auto_1fr] items-center justify-center gap-6 sm:mt-14 sm:grid-cols-[1fr_auto_1fr_auto_1fr] sm:grid-rows-1 sm:gap-4"
		>
			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">10</p>

				<p class="mt-4 text-center text-base text-muted-foreground md:text-lg">
					{m.home_numbers_metrics()}
				</p>
			</div>

			<Separator
				class="mx-auto h-[1px] w-10/12 sm:h-full sm:w-[1px]"
				orientation={data.isMobile ? 'horizontal' : 'vertical'}
			/>

			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">40+</p>

				<p class="mt-4 text-center text-base text-muted-foreground md:text-lg">
					{m.home_numbers_chains()}
				</p>
			</div>

			<Separator
				class="mx-auto h-[1px] w-10/12 sm:h-full sm:w-[1px]"
				orientation={data.isMobile ? 'horizontal' : 'vertical'}
			/>

			<div class="flex flex-col items-center justify-center">
				<p class="text-center text-5xl font-semibold sm:text-6xl">500GB+</p>

				<p class="mt-4 text-center text-base text-muted-foreground md:text-lg">
					{m.home_numbers_data()}
				</p>
			</div>
		</div>
	</section>

	<section class="flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_metrics_title()}</h3>

		<p style="max-width: 800px;" class="mt-6 text-center text-base text-muted-foreground">
			{m.home_metrics_description()}
		</p>

		<div
			style="max-width: 1000px;"
			class="mt-6 grid grid-cols-1 gap-6 md:mt-10 md:grid-cols-2 md:gap-10"
		>
			<Card.Root href="/blog/transactions-per-second-tps" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_tps_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_tps_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root href="/blog/transactions-per-second-tps" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_max_tps_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_max_tps_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root href="/blog/transactions-per-second-tps" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_theor_tps_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_theor_tps_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root href="/blog/block-time" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_blocktime_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_blocktime_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root href="/blog/time-to-finality-ttf" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_ttf_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_ttf_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root href="/blog/blockchain-governance" target="_blank">
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_governance_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_governance_description()}
					</p>
				</Card.Content>
			</Card.Root>

			<Card.Root>
				<Card.Header>
					<Card.Title class="font-bold md:text-center" tag="h4">
						{m.home_metrics_launched_date_name()}
					</Card.Title>
				</Card.Header>
				<Card.Content>
					<p class="text-muted-foreground">
						{m.home_metrics_launched_date_description()}
					</p>
				</Card.Content>
			</Card.Root>
		</div>
	</section>

	<section style="max-width: 700px;" class="mx-auto flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_chain_pages_title()}</h3>

		<p class="mt-6 text-base text-muted-foreground">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_chain_pages_description()}
		</p>

		<a
			class="mt-6 w-full rounded-lg border p-1 shadow-sm transition-all hover:scale-101 hover:border-foreground focus-visible:ring-2 focus-visible:ring-ring"
			href="/chain/ethereum"
		>
			<CldImage
				alt={m.home_chain_pages_screenshot_alt()}
				decoding="async"
				height="1279"
				loading="lazy"
				src="landing/ethereum_page_redesign_mmoqeb"
				width="1944"
			/>
		</a>

		<p class="links-inside mt-6 text-base text-muted-foreground">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_chain_pages_screenshot_subtitle({
				ethereum: '/chain/ethereum',
			})}
		</p>
	</section>

	<section style="max-width: 700px;" class="mx-auto flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_compare_pages_title()}</h3>

		<p class="mt-6 text-base text-muted-foreground">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_compare_pages_description()}
		</p>

		<a
			class="mt-6 w-full rounded-lg border p-1 shadow-sm transition-all hover:scale-101 hover:border-foreground focus-visible:ring-2 focus-visible:ring-ring"
			href="/compare/ethereum-vs-bitcoin"
		>
			<CldImage
				alt={m.home_compare_pages_screenshot_alt()}
				decoding="async"
				height="2234"
				loading="lazy"
				src="landing/ethereum_vs_bitcoin_redesign_bag9ws"
				width="1960"
			/>
		</a>

		<p class="links-inside mt-6 text-base text-muted-foreground">
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			{@html m.home_compare_pages_screenshot_subtitle({
				compare: '/compare',
				ethereumVsBitcoin: '/compare/ethereum-vs-bitcoin',
			})}
		</p>
	</section>

	<section class="relative flex flex-col items-center pt-24 sm:pt-40">
		<h3 class="text-center text-2xl font-bold sm:text-4xl">{m.home_bottom_cta_title()}</h3>

		<p style="max-width: 500px;" class="mt-6 text-center text-base text-muted-foreground">
			{m.home_bottom_cta_description()}
		</p>

		<Button
			class="mt-8"
			href="/dashboard"
			on:click={() => {
				phg?.capture('open_dashboard', {
					place: 'bottom_banner',
				})
			}}
		>
			{m.dashboard_cta()}
		</Button>
	</section>
</main>

<style>
	.top-gradient {
		background-image: radial-gradient(
			circle farthest-side at 100% 10%,
			rgba(133, 134, 173, 0.15),
			rgba(9, 9, 11, 0) 89%
		);
	}
</style>
